import React from "react";
import bg from "../public/img/bilog2.jpg";
import { Contact } from "./contact";
import { ProfileNavigation } from "./profile-navigation";

function ProfileRobert() {
  return (
    <div>
      <ProfileNavigation />
      <div
        id="profile"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingTop: "10rem",
        }}
      >
        <div className="profile-container-fluid" style={{ marginTop: "5rem" }}>
          <div className="profile-section">
            <div className="profile-img">
              <h2
                className="back"
                style={{ fontSize: "1.8rem" }}
                onClick={() => {
                  window.location.href = "/#our-team";
                }}
              >
                Back to Our Team
              </h2>
              <img
                src="/img/profile/robert.png"
                height={450}
                // className="img-responsive"
                alt=""
              />{" "}
            </div>
            <div style={{ width: "70px" }}></div>
            <div className="profile-container">
              <div className="profile-text">
                <div style={{ width: "360px" }}>
                  <h1 className="montserrat-bold dark">ROBERT G. COFFEY</h1>
                </div>

                <p
                  className="montserrat-light"
                  style={{
                    fontSize: "16px",
                    color: "#000",
                    marginBottom: "0px",
                    letterSpacing: "0.005em",
                    lineHeight: "3.5rem",
                  }}
                >
                  Bob Coffey is a Management Consultant, Advisor and Coach . He
                  is the retired vice chair and head coach of KPMG . He is also
                  a past Vice President of Geneva Merger & Acquisition Services
                  of Canada (Ont.) Inc . He was also a key advisor to an Ontario
                  Health Care provider on budgeting, finance and service
                  delivery. He’s known professionally as “THE COACH” He has held
                  numerous executive and management positions including Managing
                  Partner for Canada of the Management Consulting Services for
                  Ernst & Whinney, Executive Partner, Thorne, Ernst and Whinney
                  and Vice Chairman of KPMG. Mr. Coffey remains active in the
                  field of mergers and acquisitions, corporate growth and
                  finance, corporate turnarounds, and is a{"  "}
                  co-author of Purchase and Sale of a Business
                </p>
                <p
                  className="montserrat-light"
                  style={{
                    fontSize: "16px",
                    color: "#000",
                    marginBottom: "0px",
                    letterSpacing: "0.005em",
                    lineHeight: "3.5rem",
                  }}
                >
                  After retirement from KPMG as an active partner where he was
                  responsible for marketing and the leadership of Industry
                  Specialists and Practices, Mr. Coffey was the Senior
                  Vice-President, Commercial Lending, ABN AMRO Bank Canada from
                  1994 through 1997
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
}

export default ProfileRobert;
