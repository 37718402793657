import React from "react";
import bg from "../public/img/bilog2.jpg";
import { Contact } from "./contact";
import { ProfileNavigation } from "./profile-navigation";

function ProfileTom() {
  return (
    <div>
      <ProfileNavigation />
      <div
        id="profile"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingTop: "10rem",
        }}
      >
        <div className="profile-container-fluid" style={{ marginTop: "5rem" }}>
          <div className="profile-section">
            <div className="profile-img">
              <h2
                className="back"
                style={{ fontSize: "1.8rem" }}
                onClick={() => {
                  window.location.href = "/#our-team";
                }}
              >
                Back to Our Team
              </h2>
              <img
                src="/img/profile/tom.png"
                height={450}
                // className="img-responsive"
                alt=""
              />{" "}
            </div>
            <div style={{ width: "70px" }}></div>
            <div className="profile-container">
              <div className="profile-text">
                <h1 className="montserrat-bold dark">DR. TOM LARDER</h1>
                {/* <h1
                  style={{ fontSize: "2rem" }}
                  className="montserrat-bold dark"
                >
                  CHAIRMAN OF THE ADVISORY BOARD
                </h1> */}
                <p
                  className="montserrat-light"
                  style={{
                    fontSize: "16px",
                    color: "#000",
                    marginBottom: "45px",
                    letterSpacing: "0.005em",
                    lineHeight: "3.5rem",
                  }}
                >
                  Dr. Larder has three decades of experience in corporate
                  governance in industries ranging from medical device to real
                  estate to financial services. After stepping down from the
                  position of Chairman of the CDSPI Board in 2004, Dr. Larder
                  was honoured by the Board with the title Chairman Emeritus.
                  Previous Board experience includes Radica Canada Ltd.{" "}
                  (acquired by Mattel Inc-NYSE),{"  "}
                  Nova Scotia Health Research Foundation, Neuro Navigational
                  Corporation (acquired by Ballard Medical Products and now a
                  division of Kimberly-Clark -NYSE) and United Equities Ltd. Dr.
                  Larder has experience on Audit and Governance Committees. Dr.
                  Larder also consulted for American Composite Technology
                  (Boston) and Force Ten Inc. (Halifax)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
}

export default ProfileTom;
