import React from "react";
import bg from "../public/img/bilog2.jpg";
import { Contact } from "./contact";
import { ProfileNavigation } from "./profile-navigation";

function ProfileTony() {
  return (
    <div>
      <ProfileNavigation />
      <div
        id="profile"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingTop: "10rem",
        }}
      >
        <div className="profile-container-fluid" style={{ marginTop: "5rem" }}>
          <div className="profile-section">
            <div className="profile-img">
              <h2
                className="back"
                style={{ fontSize: "1.8rem" }}
                onClick={() => {
                  window.location.href = "/#our-team";
                }}
              >
                Back to Our Team
              </h2>
              <img
                src="/img/profile/tony.png"
                height={450}
                // className="img-responsive"
                alt=""
              />{" "}
            </div>
            <div style={{ width: "70px" }}></div>
            <div className="profile-container">
              <div className="profile-text">
                <h1 className="montserrat-bold dark">TONY CLEMENT</h1>
                {/* <h1
                  style={{ fontSize: "2rem" }}
                  className="montserrat-bold dark"
                >
                  HEALTHCARE EXPERT
                </h1> */}
                <p
                  className="montserrat-light"
                  style={{
                    fontSize: "16px",
                    color: "#000",
                    marginBottom: "45px",
                    letterSpacing: "0.005em",
                    lineHeight: "3.5rem",
                  }}
                >
                  The Honourable Tony Clement is a former Minister in the
                  Stephen Harper Government federally, and the Mike Harris and
                  Ernie Eves Governments in the Province of Ontario. During his
                  time in the House of Commons (2006-2019) Mr. Clement served
                  as: Minister of Health, Minister of Industry, President of the
                  Treasury AND Board and Minister responsible for the Federal
                  Economic Development Initiative for Northern Ontario. Mr.
                  Clement's dedication to public service spans three decades.
                  During his eight and a half years as an Ontario MPP
                  (1995-2003), he held prominent Cabinet positions in the
                  Ontario government, including{"  "}
                  Minister of Health and Long-Term Care, Minister of the
                  Environment, Minister of Municipal Affairs and Housing and
                  Minister of Transportation. As Health Minister, he
                  successfully steered Ontario through the SARS pandemic.
                  Clement operates in international circles, with strong
                  connections in the USA, Europe, South America, Asia and India.
                  Tony acts as an Advisory Board Member to Red Light Holland, a
                  Canadian public company involved in the production and
                  distribution of EU-GMP medical grade truffles.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
}

export default ProfileTony;
