import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container" id="main-nav">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>
          </button>
          <div>
            <a
              className="navbar-brand page-scroll"
              href="#page-top"
              style={{ marginTop: "-18px", marginLeft: "50px" }}
            >
              <img src="img/omega-grp-logo.jpg" height={50} alt="logo" />
            </a>
          </div>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#page-top" className="page-scroll montserrat nav-items">
                Home
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll montserrat nav-items">
                About us
              </a>
            </li>
            <li>
              <a href="#our-team" className="page-scroll montserrat nav-items">
                Our Team
              </a>
            </li>
            <li>
              <a href="#vision" className="page-scroll montserrat nav-items">
                Vision
              </a>
            </li>
            <li>
              <a
                href="#what-we-do"
                className="page-scroll montserrat nav-items"
              >
                What we do
              </a>
            </li>
            <li className="li-custom">
              <a
                href="#contact-us"
                className="btn btn-custom btn-lg page-scroll"
              >
                <div className="contact-us-btn" style={{ display: "flex" }}>
                  <span>Contact Us</span>
                  <div style={{ width: "10px" }} />
                  <span style={{ fontSize: "20px", marginTop: "-1px" }}>
                    <FontAwesomeIcon icon={faAngleDown} />
                  </span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
