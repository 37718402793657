import React from "react";
import bg from "../public/img/bilog2.jpg";
import { Contact } from "./contact";
import { ProfileNavigation } from "./profile-navigation";

function ProfileGerry() {
  return (
    <div>
      <ProfileNavigation />
      <div
        id="profile"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingTop: "10rem",
        }}
      >
        <div className="profile-container-fluid" style={{ marginTop: "5rem" }}>
          <div className="profile-section">
            <div className="profile-img">
              <h2
                className="back"
                style={{ fontSize: "1.8rem" }}
                onClick={() => {
                  window.location.href = "/#our-team";
                }}
              >
                Back to Our Team
              </h2>
              <img
                src="/img/profile/gerry.jpg"
                height={450}
                // className="img-responsive"
                alt=""
              />{" "}
            </div>
            <div style={{ width: "70px" }}></div>
            <div className="profile-container">
              <div className="profile-text">
                <div style={{ width: "400px" }}>
                  <h1 className="montserrat-bold dark">GERRY HENLEY</h1>
                </div>

                <p
                  className="montserrat-light"
                  style={{
                    fontSize: "16px",
                    color: "#000",
                    marginBottom: "0px",
                    letterSpacing: "0.005em",
                    lineHeight: "3.5rem",
                  }}
                >
                  Gerry has more than 30 years’ experience as a strategic,
                  results-driven business development and leadership executive
                  and more than 15 years with franchise systems. He uses his
                  expertise in leadership, operations, strategic planning,
                  financial management, process improvement and team building to
                  help companies scale and execute their organizational vision.
                  He is currently president at Launch to Growth, a consulting
                  company in franchise operational strategies and leadership.
                </p>
                <p
                  className="montserrat-light"
                  style={{
                    fontSize: "16px",
                    color: "#000",
                    marginBottom: "0px",
                    letterSpacing: "0.005em",
                    lineHeight: "3.5rem",
                  }}
                >
                  Prior to that Gerry was executive vice president of Office
                  Pride Commercial Cleaning Services, where he served as the
                  integrator to the CEO. During his tenure of 4 years, he
                  implemented EOS Traction®; increased average franchise unit
                  sales by more than 200%; increased overall system sales
                  revenue from $21 million to $68 million and maintained profits
                  by 18% or more per year.
                </p>
                <p
                  className="montserrat-light"
                  style={{
                    fontSize: "16px",
                    color: "#000",
                    marginBottom: "0px",
                    letterSpacing: "0.005em",
                    lineHeight: "3.5rem",
                  }}
                >
                  Gerry serves on the advisory board of the Titus Center for
                  Franchising at Palm Beach Atlantic University in West Palm
                  Beach, Fla., as co-founder and leader of Renovate Men’s
                  Ministries in La Crosse, Wis., and Safety Harbor, Fla. and
                  Area Ambassador for Wingman Nation Men’s Ministries in the
                  Clearwater Fla. area.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
}

export default ProfileGerry;
