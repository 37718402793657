import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./public/fonts/montserrat-bold.ttf";
import "./public/fonts/montserrat-regular.ttf";
import "./public/fonts/montserrat-extra-bold.ttf";
import "./public/fonts/montserrat-light.ttf";
import "./index.css";
import App from "./App";
import ProfileTom from "./components/profile-tom";
import ProfileTony from "./components/profile-tony";
import ProfileAhnaf from "./components/profile-ahnaf";
import ProfilePierre from "./components/profile-pierre";
import ProfileCarl from "./components/profile-carl";
import ProfileTim from "./components/profile-tim";
import ProfileRobert from "./components/profile-robert";
import ProfileDonald from "./components/profile-donald";
import ProfileKevin from "./components/profile-kevin";
import ProfileGerry from "./components/profile-gerry";
import ProfileMauro from "./components/profile-mauro";
// import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route index element={<App />} />
        <Route path="/" element={<App />} />
        <Route path="/profile/tom-larder" element={<ProfileTom />} />
        <Route path="/profile/tony-clement" element={<ProfileTony />} />
        <Route path="/profile/ahnaf-shahriar" element={<ProfileAhnaf />} />
        <Route path="/profile/pierre-yves-martel" element={<ProfilePierre />} />
        <Route path="/profile/carl-gauvreau" element={<ProfileCarl />} />
        <Route path="/profile/tim-mccunn" element={<ProfileTim />} />
        <Route path="/profile/robert-coffey" element={<ProfileRobert />} />
        <Route path="/profile/donald-benson" element={<ProfileDonald />} />
        <Route path="/profile/gerry-henley" element={<ProfileGerry />} />
        <Route path="/profile/mauro-scigliano" element={<ProfileMauro />} />
        {/* <Route path="/profile/kevin-mckenzie" element={<ProfileKevin />} /> */}
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
