import React from "react";
import bg from "../public/img/bilog2.jpg";
import { Contact } from "./contact";
import { ProfileNavigation } from "./profile-navigation";

function ProfileMauro() {
  return (
    <div>
      <ProfileNavigation />
      <div
        id="profile"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingTop: "10rem",
        }}
      >
        <div className="profile-container-fluid" style={{ marginTop: "5rem" }}>
          <div className="profile-section">
            <div className="profile-img">
              <h2
                className="back"
                style={{ fontSize: "1.8rem" }}
                onClick={() => {
                  window.location.href = "/#our-team";
                }}
              >
                Back to Our Team
              </h2>
              <img
                src="/img/profile/mauro.jpg"
                height={450}
                // className="img-responsive"
                alt=""
              />{" "}
            </div>
            <div style={{ width: "70px" }}></div>
            <div className="profile-container">
              <div className="profile-text">
                <div style={{ width: "440px" }}>
                  <h1 className="montserrat-bold dark">MAURO R.D. SCIGLIANO</h1>
                </div>

                <p
                  className="montserrat-light"
                  style={{
                    fontSize: "16px",
                    color: "#000",
                    marginBottom: "0px",
                    letterSpacing: "0.005em",
                    lineHeight: "3.5rem",
                  }}
                >
                  Proven expertise with an outstanding record of accomplishments
                  in managing and leading major FM businesses with 35 plus
                  years’ experience. Seasoned leader with demonstrated abilities
                  in creating and building management teams, managing
                  acquisitions, divestitures, and company turnarounds.
                </p>
                <p
                  className="montserrat-light"
                  style={{
                    fontSize: "16px",
                    color: "#000",
                    marginBottom: "0px",
                    letterSpacing: "0.005em",
                    lineHeight: "3.5rem",
                  }}
                >
                  Mr. Scigliano has led major corporation in the Facility
                  Service space, as its President for ABM industries Facility
                  Services Group, Group President and CIO for GCA Inc, and COO
                  of North American operations for ISS. He has transitioned from
                  single service business to integrated FM services platform,
                  systems and process transformation that positioned companies
                  in bringing value to their customers and enhancing the bottom
                  line.
                </p>
                <p
                  className="montserrat-light"
                  style={{
                    fontSize: "16px",
                    color: "#000",
                    marginBottom: "0px",
                    letterSpacing: "0.005em",
                    lineHeight: "3.5rem",
                  }}
                >
                  Excellent cross industry experience, from all aspects of FM to
                  leading sales and marketing groups. Proven record of improving
                  and sustaining growth, profits, and a culture for all in which
                  to succeed.
                </p>
                <p
                  className="montserrat-light"
                  style={{
                    fontSize: "16px",
                    color: "#000",
                    marginBottom: "0px",
                    letterSpacing: "0.005em",
                    lineHeight: "3.5rem",
                  }}
                >
                  Mauro attended College of San Mateo, and majored in Business
                  and real estate, and currently lives with his family of wife
                  Leslie and their four children in San Antonio, TX.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
}

export default ProfileMauro;
