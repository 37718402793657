import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGears, faHandshakeAlt, faArrowUpRightDots } from '@fortawesome/free-solid-svg-icons'

export const WhatWeDo = () => {
    return (
        <div id='what-we-do' style={{ backgroundColor: '#f4f4f4' }}>
            <div className="section-title fa-label-text" style={{ marginBottom: '10px' }}>
                <h2 className="fa-icon" style={{ fontSize: '60px', marginTop: '0px', color: '#2174c9' }}><FontAwesomeIcon icon={faGears} /></h2>
                <h2 className="montserrat dark" style={{ marginLeft: '20px' }}>What we do</h2>
            </div>
            <div className='what-we-do-container-fluid'>
                <div className='what-we-do-section'>
                    <div className='what-we-do-container one'>
                        <div className='what-we-do-text'>
                            <h2 className="montserrat dark text-center icon" style={{ fontSize: '60px', marginTop: '0px', color: '#2174c9' }}><FontAwesomeIcon icon={faArrowUpRightDots} /></h2>
                            <br />
                            <p className="montserrat-light" style={{ fontSize: '22px', color: '#000', textAlign: 'center' }}><b className="montserrat-bold">We acquire established businesses</b> with a track record of success. Our model leverages client strengths and streamlines expenses, leading to higher revenue and improved spending. We invest as majority partners, provide current management the resources to dominate the competition, and focus on sustainable long-term growth, rather than short-term gains.</p>
                        </div>
                    </div>
                    <div style={{ width: '50px' }} />
                    <div className='what-we-do-container'>
                        <div className='what-we-do-text'>
                        <h2 className="montserrat dark text-center icon" style={{ fontSize: '60px', marginTop: '0px', color: '#2174c9' }}><FontAwesomeIcon icon={faHandshakeAlt} /></h2>
                            <br />
                            <p className="montserrat-light" style={{ fontSize: '22px', color: '#000', textAlign: 'center' }}><b className="montserrat-bold">We work with you</b> through transition. Whether you want to continue with the business, completely exit, or transition over time, we will work with you to create a plan that minimizes disruption to daily operations. We work efficiently to complete the transaction in an aggressive time frame. The Omega Group maintains strict confidentiality throughout the process.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
