import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import bg from "../public/img/bilog2.jpg";

export const Vision = () => {
  return (
    <div
      id="vision"
      className="text-center"
      style={{ backgroundColor: "#f1f1f1" }}
    >
      <div className="container">
        <div className="section-title">
          <div className="fa-label-text">
            <h2
              className="fa-icon"
              style={{ fontSize: "60px", marginTop: "0px", color: "#2174c9" }}
            >
              <FontAwesomeIcon icon={faEye} />
            </h2>
            <h2 className="montserrat dark" style={{ marginLeft: "20px" }}>
              Our Vision
            </h2>
          </div>
          <br />
          <p
            className="montserrat-light"
            style={{ fontSize: "24px", color: "#000" }}
          >
            <b className="montserrat-bold">
              To be the leader in providing services to facility
              maintenance companies.
            </b>
          </p>
          <p
            className="montserrat-light"
            style={{ fontSize: "20px", color: "#000" }}
          >
            The best professionals will partner with us because of who we are,
            the support and compensation they receive, and what we represent in
            our respective communities. The results are expected to generate
            industry-leading shareholder returns.
          </p>
          {/* <p className="montserrat-light" style={{ fontSize: '18px', color: '#000' }}></p> */}
        </div>
      </div>
    </div>
  );
};
