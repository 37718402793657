import React from "react";
import bg from "../public/img/bilog2.jpg";
import { Contact } from "./contact";
import { ProfileNavigation } from "./profile-navigation";

function ProfilePierre() {
  return (
    <div>
      <ProfileNavigation />
      <div
        id="profile"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingTop: "10rem",
        }}
      >
        <div className="profile-container-fluid" style={{ marginTop: "5rem" }}>
          <div className="profile-section">
            <div className="profile-img">
              <h2
                className="back"
                style={{ fontSize: "1.8rem" }}
                onClick={() => {
                  window.location.href = "/#our-team";
                }}
              >
                Back to Our Team
              </h2>
              <img
                src="/img/profile/pierre.png"
                height={450}
                // className="img-responsive"
                alt=""
              />{" "}
            </div>
            <div style={{ width: "70px" }}></div>
            <div className="profile-container">
              <div className="profile-text">
                <h1 className="montserrat-bold dark">PIERRE - YVES MARTEL</h1>
                <p
                  className="montserrat-light"
                  style={{
                    fontSize: "16px",
                    color: "#000",
                    marginBottom: "45px",
                    letterSpacing: "0.005em",
                    lineHeight: "3.5rem",
                  }}
                >
                  Pierre-Yves Martel has had a career in the information
                  technology industry for the past 40 years. He holds a
                  bachelor’s degree in administration and a master’s degree in
                  information systems from Laval University. In 1997, he joined
                  Gespro technologie as vice-president of professional services
                  until November 2001 when, together with his partners, they
                  sold their professional services division to TELUS.From 2001
                  to 2008, Mr. Martel held various positions at TELUS within
                  their professional services division. He oversaw professional
                  services for Eastern Quebec, responsible for the integration
                  of 7 companies acquired by TELUS and was responsible for the
                  two TELUS data centers in Quebec. In 2008 he left TELUS to
                  take up a new challenge at{"  "}
                  Victrix as CEO of a professional services company, which he
                  completely restructured. In 2011 he returned to TELUS to lead
                  TELUS Professional Services in Quebec, a division he would
                  lead until the sale and investment in Alithya in 2016. During
                  these 40 years, Mr. Martel has participated in more than 10
                  acquisitions or sales of information technology firms and the
                  launch of a public company (N’stein Technologies). He has
                  received several industry recognitions including in 2010,
                  Rookie of the year from the Venture tech network of Ingram
                  Micro, 2011 Microsoft Canadian Partner of the Year,
                  personality of the month for October 2011 from the TI Network.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
}

export default ProfilePierre;
