import React from "react";
import bg from "../public/img/bilog2.jpg";
import { Contact } from "./contact";
import { ProfileNavigation } from "./profile-navigation";

function ProfileAhnaf() {
  return (
    <div>
      <ProfileNavigation />
      <div
        id="profile"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingTop: "10rem",
        }}
      >
        <div className="profile-container-fluid" style={{ marginTop: "5rem" }}>
          <div className="profile-section">
            <div className="profile-img">
              <h2
                className="back"
                style={{ fontSize: "1.8rem" }}
                onClick={() => {
                  window.location.href = "/#our-team";
                }}
              >
                Back to Our Team
              </h2>
              <img
                src="/img/profile/ahnaf.png"
                height={450}
                // className="img-responsive"
                alt=""
              />{" "}
            </div>
            <div style={{ width: "70px" }}></div>
            <div className="profile-container">
              <div className="profile-text">
                <h1 className="montserrat-bold dark">AHNAF SHAHRIAR</h1>
                {/* <h1
                  style={{ fontSize: "2rem" }}
                  className="montserrat-bold dark"
                >
                  FOUNDER & PRESIDENT
                </h1> */}
                <p
                  className="montserrat-light"
                  style={{
                    fontSize: "16px",
                    color: "#000",
                    marginBottom: "45px",
                    letterSpacing: "0.005em",
                    lineHeight: "3.5rem",
                  }}
                >
                  Ahnaf Shahriar has multiple years of experience in the
                  Information Technology sector and has enjoyed a successful
                  corporate career. His deep understanding of the Networking,
                  Telecommunication and Cyber Security domain has enabled him to
                  cover large sales territories for IT manufacturers such as{" "}
                  Cisco Meraki and Infoblox. Ahnaf holds a Bachelor’s Degree in
                  Computer Science from Concordia University with several Cisco
                  Systems certifications including the highly coveted{"  "}
                  Cisco Certified Internetwork Expert (CCIE) Security.
                </p>
                <p
                  className="montserrat-light"
                  style={{
                    fontSize: "16px",
                    color: "#000",
                    marginBottom: "45px",
                    letterSpacing: "0.005em",
                    lineHeight: "3.5rem",
                  }}
                >
                  As the Founder, Ahnaf is responsible for business and
                  corporate development. He is a proven business professional{" "}
                  who knows how to identify opportunities and a leader who has
                  demonstrated an ability to build and develop teams in which
                  everyone can use their talents in the best possible way to
                  achieve a common goal.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
}

export default ProfileAhnaf;
