import videoBg from "../public/vid/dubai.mp4";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        {/* <ParticlesBg type="circle" bg={{zIndex: 0, position:"absolute", top:0}} /> */}

        <div className="video-container">
          <div className="video-overlay"></div>
          <video className="video-bg" autoPlay loop muted>
            <source src={videoBg} type="video/mp4" />
          </video>
        </div>
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                {/* <h2 style={{ color: "#4d99eb", fontSize: "40px" }}>
                  The Omega Group
                </h2> */}
                {/* <div style={{ marginBottom: '50px' }}>
                  <img id="header-logo" src="img/omega-grp-png.png" alt="logo" height={100} />
                </div> */}
                <p
                  className="header-info"
                >
                  The Omega Group is an investment consortium dedicated to
                  acquiring and consolidating facility maintenance companies in
                  North America. We assist owners with an exit strategy while
                  protecting the legacy they have sacrificed to build.
                </p>
                <a href="#about" className="btn btn-custom btn-lg page-scroll">
                  Learn More
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
