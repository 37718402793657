import bg from "../public/img/bilog2.jpg";

export const About = (props) => {
  return (
    <div id="about" style={{ backgroundColor: "#f1f1f1" }}>
      <div className="about-container-fluid">
        <div className="about-section">
          <div className="about-img">
            <img
              src="img/montreal-buildings.jpg"
              className="img-responsive"
              alt=""
            />{" "}
          </div>
          <div className="about-container">
            <div className="about-text">
              <h2 className="montserrat dark">About Us</h2>
              <br />
              <br />
              <br />
              {/* <p className="montserrat-light" style={{ fontSize: '19px', color: '#000', marginBottom: '45px' }}><b className="montserrat-bold">The Omega Group</b> is an <i className="montserrat-light">investment consortium</i> dedicated to acquiring and consolidating managed service providers and system integrators in the <b className="montserrat-bold">Information Technology sector.</b></p> */}
              <p
                className="montserrat-light"
                style={{ fontSize: "23px", color: "#000", fontStyle: "italic" }}
              >
                With diversified backgrounds in Information Technology, M&A
                transactions, Corporate Law, Entrepreneurship and Leadership, we
                have formed a formidable team with a track record of 300+ years
                of business experience and
                <b className="montserrat-bold">
                  {" "}
                  tens of billions and dollars{" "}
                </b>
                in transaction experience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
