import React from "react";
import bg from "../public/img/bilog2.jpg";
import { Contact } from "./contact";
import { ProfileNavigation } from "./profile-navigation";

function ProfileTim() {
  return (
    <div>
      <ProfileNavigation />
      <div
        id="profile"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingTop: "10rem",
        }}
      >
        <div className="profile-container-fluid" style={{ marginTop: "5rem" }}>
          <div className="profile-section">
            <div className="profile-img">
              <h2
                className="back"
                style={{ fontSize: "1.8rem" }}
                onClick={() => {
                  window.location.href = "/#our-team";
                }}
              >
                Back to Our Team
              </h2>
              <img
                src="/img/profile/tim.png"
                height={450}
                className="profile-picture"
                // className="img-responsive"
                alt=""
              />{" "}
            </div>
            <div style={{ width: "70px" }}></div>
            <div className="profile-container">
              <div className="profile-text">
                <h1 className="montserrat-bold dark">TIM MUCCUNN</h1>
                {/* <h1
                  style={{ fontSize: "2rem" }}
                  className="montserrat-bold dark"
                >
                  LEGAL EXPERT
                </h1> */}
                <p
                  className="montserrat-light"
                  style={{
                    fontSize: "15px",
                    color: "#000",
                    marginBottom: "45px",
                    letterSpacing: "0.005em",
                    lineHeight: "3.5rem",
                  }}
                >
                  Tim’s practice is focused on corporate/commercial and
                  securities law with particular emphasis on mergers and
                  acquisitions and corporate finance for the technology and life
                  sciences sectors. He has advised on over 500 transactions
                  involving the financing of both technology and biotechnology
                  companies across Canada acting as counsel to issuers and
                  venture capital funds. In addition, he has advised on initial
                  public offerings, capital pool corporations, reverse
                  take-overs, take-overs, purchase and sale transactions, joint
                  ventures, licensing/co-development and distribution
                  arrangements as well as complex commercial agreements. With
                  respect to not-for-profit organizations and public entities,
                  specific assignments include board and governance advice,
                  legislative drafting, opinion work on a wide variety of issues
                  as well as representations to government . In 1992 he was
                  seconded to the Privy Council Office as{"  "}
                  Special Counsel to advise on the drafting of the Referendum
                  Act as well as other matters related to the 1992
                  constitutional referendum. A fluently bilingual lawyer, Tim
                  has been recognized repeatedly in Canadian Legal Lexpert’s
                  Directory in the area of Corporate Commercial Law; Corporate
                  mid-market and Private Equity. He has served as a Director of
                  several private technology companies, including Annidis Health
                  Systems Corp., Cognitive Sensing Inc. and Nantucket Hedge Fund
                  Inc. and acted as counsel to independent committees of the
                  board regarding sale of assets transactions
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
}

export default ProfileTim;
