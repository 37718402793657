import { useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Contact = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  console.log(errors, "TEST");

  const sendEmail = () => {
    setIsLoading(true);
    emailjs
      .sendForm(
        "service_t6h5o2k",
        "template_b2b1n75",
        "#myForm",
        "hk15GiFgM_4JO7UyX"
      )
      .then(
        (result) => {
          alert(
            "Message successfully sent! We will reach out to you in due course."
          );
          setIsLoading(false);
          console.log(result.text);
        },
        (error) => {
          alert("Something went wrong, Please try again later.");
          setIsLoading(false);
          console.log(error.text, "ERROR");
        }
      );
    reset();
  };

  return (
    <div>
      <div id="contact-us">
        <div className="container">
          <div className="col-md-8 col-md-offset-2">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <div>
                  <p style={{ fontSize: "20px", color: "white" }}>
                    <span style={{ color: "#24c5f0" }}>
                      <FontAwesomeIcon icon={faPhone} />
                    </span>{" "}
                    {"   "} 514-831-9233
                  </p>
                </div>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form
                id="myForm"
                onSubmit={handleSubmit((event) => sendEmail(event))}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        {...register("name", { required: "Name is required" })}
                      />
                      <p className="help-block text-danger">
                        {errors.name?.message}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        {...register("email", {
                          required: "Email is required",
                        })}
                      />
                      <p className="help-block text-danger">
                        {errors.email?.message}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="company_name"
                        name="company_name"
                        className="form-control"
                        placeholder="Company Name"
                        {...register("companyName", {
                          required: "Company name is required",
                        })}
                      />
                      <p className="help-block text-danger">
                        {errors.companyName?.message}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="number"
                        id="no_employees"
                        name="no_employees"
                        className="form-control"
                        placeholder="Number of employees"
                        {...register("numOfEmployees", {
                          required: "Number of employees is required",
                        })}
                      />
                      <p className="help-block text-danger">
                        {errors.numOfEmployees?.message}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <select
                        class="form-control"
                        {...register("annualRevenue", {
                          required: "Annual revenue is required",
                        })}
                      >
                        <option value="" selected>
                          Please Select Approx. Annual Revenue
                        </option>
                        <option value="$0 - $500k">$0 - $500k</option>
                        <option value="$500k - $1m">$500k - $1m</option>
                        <option value="$1m - $2m">$1m - $2m</option>
                        <option value="$2m - $5m">$2m - $5m</option>
                        <option value="$5m+">$5m+</option>
                      </select>
                      <p className="help-block text-danger">
                        {errors.annualRevenue?.message}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message (Optional)"
                    {...register("message")}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <div className="inquiry">
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="btn btn-custom btn-lg"
                  >
                    Send Inquiry
                  </button>

                  {/* <div>
                    <p style={{ fontSize: "20px", color: "white" }}>
                      <FontAwesomeIcon icon={faPhone} /> {"   "} 514-831-9233
                    </p>
                  </div> */}
                </div>

                {/* <div>
                  <p style={{ fontSize: "20px" }}>
                    <FontAwesomeIcon icon={faPhone} /> {"   "} 514-831-9233
                  </p>
                </div> */}
              </form>
            </div>
          </div>

          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <div className="container text-center">
                  <p
                    className="montserrat-light"
                    style={{ fontSize: "17px", color: "#fff" }}
                  >
                    &copy; 2023 by <b className="montserrat-bold">The Omega Group</b>.
                    All Rights Reserved
                  </p>
                </div>
                {/* <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
