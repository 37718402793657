export const Features = (props) => {
  return (
    <div id="our-team" className="text-center">
      <div className="container">
        <div className="section-title">
          <div className="fa-label-text">
            <h2 className="montserrat dark" style={{ marginLeft: "20px" }}>
              Our Team
            </h2>
          </div>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-xs-12 col-md-4">
                  {" "}
                  <img
                    className="people"
                    height={300}
                    src={d.img}
                    alt=""
                  />{" "}
                  <div
                  // style={{
                  //   background: "#081229",
                  //   padding: "0.3rem",
                  //   maxWidth: "240px",
                  //   margin: "auto",
                  //   marginTop: "10px",
                  // }}
                  >
                    <p
                      className="view-profile"
                      onClick={() => {
                        window.location.href = `/profile/${d.url}`;
                      }}
                    >
                      View Profile
                    </p>
                    <h4
                      className="montserrat-bold"
                      style={{ fontSize: "1.8rem" }}
                    >
                      <b>{d.title}</b>
                    </h4>
                    <h4 style={{ fontSize: "1.5rem" }}>{d.text}</h4>
                  </div>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
