import React from "react";
import bg from "../public/img/bilog2.jpg";
import { Contact } from "./contact";
import { ProfileNavigation } from "./profile-navigation";

function ProfileDonald() {
  return (
    <div>
      <ProfileNavigation />
      <div
        id="profile"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingTop: "10rem",
        }}
      >
        <div className="profile-container-fluid" style={{ marginTop: "5rem" }}>
          <div className="profile-section">
            <div className="profile-img">
              <h2
                className="back"
                style={{ fontSize: "1.8rem" }}
                onClick={() => {
                  window.location.href = "/#our-team";
                }}
              >
                Back to Our Team
              </h2>
              <img
                src="/img/profile/donald.png"
                height={450}
                // className="img-responsive"
                alt=""
              />{" "}
            </div>
            <div style={{ width: "70px" }}></div>
            <div className="profile-container">
              <div className="profile-text">
                <h1 className="montserrat-bold dark">Donald T. Benson</h1>
                <p
                  className="montserrat-light"
                  style={{
                    fontSize: "16px",
                    color: "#000",

                    letterSpacing: "0.005em",
                    lineHeight: "3.5rem",
                  }}
                >
                  Mr. Donald Benson, Non-Exec M&A Advisor, Mr. Benson has over
                  fifty-five year’s track record as Chairman of the Board, CEO,
                  President, Senior Vice President and Director having
                  integrated billion-dollar companies and restructuring them
                  throughout much of his career. He also has extensive computer
                  systems experience with Aetna Life and Casualty (Fortune 50)
                  and CIGNA (Fortune 50) Corp Mr. Benson has worked in eleven
                  sectors and either led, or a key member of the team to
                  purchase, merge or sale over{"  "}
                  one hundred companies.
                </p>
                <p
                  className="montserrat-light"
                  style={{
                    fontSize: "16px",
                    color: "#000",

                    letterSpacing: "0.005em",
                    lineHeight: "3.5rem",
                  }}
                >
                  Mr. Benson has enjoyed major success being a key leader in
                  negotiations for Aetna’s $4.5B sale of its property casualty
                  division to Travelers City Group and a key member of the team
                  in the acquisition of US Healthcare for $9B. He also saved
                  Aetna over $1 billion over four years by transforming Aetna’s
                  Human Resource Operation to a strategic partner. While with{" "}
                  CIGNA. one of his responsibilities included the integrating of
                  forty-eight acquired companies into CIGNA’s corporate
                  structure. Mr. Benson was also a senior executive with
                  Coventry Health Insurance (Fortune 500) and The Methodist
                  Health Care System. At Methodist he led a $100 million
                  turnaround. Mr. Benson’s background also includes key roles
                  with The United States Department of Defense, The Pentagon,
                  The White House and other related agencies in Washington D.C.,
                  Hawaii and the Far East. Mr. Benson also served a tour of duty
                  in the U.S. Army..
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
}

export default ProfileDonald;
