import React from "react";
import bg from "../public/img/bilog2.jpg";
import { Contact } from "./contact";
import { ProfileNavigation } from "./profile-navigation";

function ProfileCarl() {
  return (
    <div>
      <ProfileNavigation />
      <div
        id="profile"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingTop: "10rem",
        }}
      >
        <div className="profile-container-fluid" style={{ marginTop: "5rem" }}>
          <div className="profile-section">
            <div className="profile-img">
              <h2
                className="back"
                style={{ fontSize: "1.8rem" }}
                onClick={() => {
                  window.location.href = "/#our-team";
                }}
              >
                Back to Our Team
              </h2>
              <img
                src="/img/profile/carl.png"
                height={450}
                // className="img-responsive"
                alt=""
              />{" "}
            </div>
            <div style={{ width: "70px" }}></div>
            <div className="profile-container">
              <div className="profile-text">
                <div style={{ width: "330px" }}>
                  <h1 className="montserrat-bold dark">CARL GAUVREAU</h1>
                  {/* <h1
                    style={{
                      fontSize: "2rem",
                      textAlign: "center",
                      marginTop: "-10px",
                    }}
                    className="montserrat-bold dark"
                  >
                    (FCPA, FCA, MBA)
                  </h1> */}
                </div>
                {/* <h1
                  style={{ fontSize: "2rem" }}
                  className="montserrat-bold dark"
                >
                  FINANCE EXPERT
                </h1> */}
                <p
                  className="montserrat-light"
                  style={{
                    fontSize: "19px",
                    color: "#000",
                    marginBottom: "0px",
                    letterSpacing: "0.005em",
                    lineHeight: "3.5rem",
                  }}
                >
                  Accomplished executive with more than 35 years of experience{" "}
                  leading finance, accounting and operational teams at large
                  private and public companies in Canada, the US and
                  internationally at the following organizations: KDC, Garda
                  World, Hartco, and Quebecor World . He started his career at
                  KPMG and is currently the president of Gauco a consulting
                  company in strategy and finance also invested in a resourcing
                  business North of Montreal.{"  "}
                </p>
                <p
                  className="montserrat-light"
                  style={{
                    fontSize: "19px",
                    color: "#000",
                    marginBottom: "0px",
                    letterSpacing: "0.005em",
                    lineHeight: "3.5rem",
                  }}
                >
                  FEI Member originally in NY (2002) he is currently Ambassador
                  and Board Member , he served as Chair of FEI Canada as well as
                  President of the Quebec Chapter and in various not-for-profit
                  organizations throughout the years. He is the author of an
                  OCPAQ training guide for young CFO’s: ‘le passage au rôle de
                  CFO: les premiers 90 jours.’{" "}
                </p>
                <p
                  className="montserrat-light"
                  style={{
                    fontSize: "19px",
                    color: "#000",
                    marginBottom: "0px",
                    letterSpacing: "0.005em",
                    lineHeight: "3.5rem",
                  }}
                >
                  He holds a Bachelor degree from HEC and an MBA in{" "}
                  International finance from McGill.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
}

export default ProfileCarl;
